import React from "react"
import { graphql } from "gatsby"
import { startCase } from "lodash"
import Color from "color"
import Bg from "../components/Bg"
import Button from "../components/Button"
import Duplet from "../components/Duplet"
import Container from "../components/Container"
import Img from "../components/Img"
import Markdown from "../components/Markdown"
import Section from "../components/Section"
import Site from "../components/Site"
import Svg from "../components/Svg"
import { formatLink, slugify } from "../helpers"
import "../css/ministry-branch.scss"

let showBcCounselorPhotos = false

let dataBcCounselors = [
  { name : "Katie Babirak"    },
  { name : "Denise Beckmann"  },
  { name : "Greg Derrick"     },
  { name : "Misty Derrick"    },
  { name : "Andy Eaves"       },
  { name : "Renae Garrett"    },
  { name : "Tim Garrett"      },
  { name : "Rebecca Luttrell" },
  { name : "Reagan Martin"    },
  { name : "Dan Rodriguez"    },
  { name : "Sandra Shobe"     },
  { name : "Kenny Williams"   },
]

let dataBcSupportStaff = [
  { name : "Daniel Starnes" },
  { name : "Missy Snider"   },
]

const MinistryBranch = ({data}) => {
  const dataMinistryBranch = data.datoCmsMinistryBranch
  const dataColors         = dataMinistryBranch.image.colors

  const cBase      = Color(dataColors[1].hex).mix(Color('#000000'), 0.57)
  const cBaseHead  = Color(dataColors[dataColors.length - 1].hex).mix(Color('#000000'), 0.89)
  const cBaseBg    = Color(dataColors[1].hex).mix(Color('#ffffff'), 0.99)
  const cBaseBgAlt = Color(dataColors[1].hex).mix(Color('#ffffff'), 0.78)
  const cAccent    = dataColors[0].hex
  const cAccentAlt = dataColors[1].hex

  return (
    <Site
      title      = {dataMinistryBranch.name}
      className  = "hcc-ministry-branch"
      themeColor = {cBaseHead}
      style      = {{
        '--c-base'           : cBase,
        '--c-base-head'      : cBaseHead,
        '--c-base-accent'    : cAccent,
        '--c-base-bg'        : cBaseBg,
        '--c-base-bg-alt'    : cBaseBgAlt,
        '--c-header-top-a'   : cAccentAlt,
        '--c-header-divider' : cAccentAlt,
        '--c-footer-bg'      : cBaseHead,
        '--c-btn-bg'         : cAccent,
        '--c-btn-bg-int'     : cAccent,
        '--c-btn-bg-active'  : cAccent,
      }}
    >
      <article>
        <Section tag="header" className="hcc-ministry-branch-intro">
          <Container size="xl">
            <h1><span>{dataMinistryBranch.name}</span></h1>
            <Img
              src    = {dataMinistryBranch.image.url}
              alt    = {dataMinistryBranch.name}
              width  = {dataMinistryBranch.image.width}
              height = {dataMinistryBranch.image.height}
              hash   = {dataMinistryBranch.image.blurhash}
            >
              <div className="hcc-ministry-branch-intro-links">
                <div className="hcc-ministry-branch-intro-links-inner">
                  <Button
                    href   = {formatLink(dataMinistryBranch.ministry.slug, 'm')}
                    prefix = {<i><Svg type="ctrl-left"/></i>}
                    label  = {`Back to ${startCase(dataMinistryBranch.ministry.slug)}`}
                  />
                </div>
              </div>
            </Img>
          </Container>
          <Bg layers={[{ opacity : 0.55, stack : [{ stops : ['#090909', '#020202'] }] }, { hash : dataMinistryBranch.image.blurhash }]}/>
        </Section>
        {(dataMinistryBranch.slug === 'biblical-counseling') &&
          <>
            <Section className="hcc-ministry-branch-bc-mission">
              <Container size="lg">
                <Duplet
                  h      = "2"
                  title  = "Discipling the Whole&nbsp;Person"
                  text   = "We exist to help people discover who Jesus really is, and live with, like, and for Him, now and&nbsp;forever."
                  center = {['xs', 'sm', 'md', 'lg', 'xl']}
                />
                <Button
                  label  = "Need Counseling?"
                  href   = "https://yourhillside.churchcenter.com/people/forms/363340"
                  target = "_blank"
                />
              </Container>
            </Section>
            {showBcCounselorPhotos &&
              <Section className="hcc-ministry-branch-bc-counselors">
                <Container size="hero">
                  <h2>Meet Your Counselors</h2>
                  <ul className="hcc-grid">
                    {dataBcCounselors.map(el => (
                      <li>
                        <Img src={`/biblical_counseling/${slugify(el.name)}.jpg`} alt={el.name}/>
                        <h3><span><i>{el.name.split(' ').shift()}</i><br/><b>{el.name.split(' ').pop()}</b></span></h3>
                      </li>
                    ))}
                  </ul>
                  <h2>Support Staff</h2>
                  <ul className="hcc-grid">
                    {dataBcSupportStaff.map(el => (
                      <li>
                        <Img src={`/biblical_counseling/${slugify(el.name)}.jpg`} alt={el.name}/>
                        <h3><span><i>{el.name.split(' ').shift()}</i><br/><b>{el.name.split(' ').pop()}</b></span></h3>
                      </li>
                    ))}
                  </ul>
                </Container>
              </Section>
            }
          </>
        }
        <Section className="hcc-ministry-branch-content">
          <Container size="md">
            <Markdown
              tag       = "div"
              type      = {null}
              className = "has-styled-links"
              source    = {dataMinistryBranch.content}
            />
          </Container>
        </Section>
        {dataMinistryBranch.contactLink &&
          <Section className="hcc-ministry-branch-prompt">
            <a href={dataMinistryBranch.contactLink}>
              <Img
                src    = {dataMinistryBranch.image.url}
                alt    = {dataMinistryBranch.name}
                width  = {dataMinistryBranch.image.width}
                height = {dataMinistryBranch.image.height}
                hash   = {dataMinistryBranch.image.blurhash}
              />
              <p>
                <span>Interested in learning more?</span>
                {dataMinistryBranch.contacts.length > 0 &&
                  <span>Reach out to our team headed up by <strong>{dataMinistryBranch.contacts[0].name}</strong> to talk through any questions you might have!</span>
                }
                {dataMinistryBranch.contacts === null &&
                  <span>Reach out to our team to talk through any questions you might have!</span>
                }
                <br/>
                <Button
                  tag   = "span"
                  label = {dataMinistryBranch.contacts.length > 0 ? `Contact ${dataMinistryBranch.contacts[0].name.split(' ').shift()}` : "Contact Our Team"}
                />
              </p>
            </a>
          </Section>
        }
      </article>
    </Site>
  )
}

export default MinistryBranch

export const query = graphql`
  query MinistryBranchQuerySingle($slug: String!) {
    datoCmsMinistryBranch(slug: { eq: $slug }) {
      ...MinistryBranchFragment
    }
  }
`
